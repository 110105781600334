<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal } from 'bootstrap-vue'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'
import 'vue2-datepicker/index.css'
import Crown from '@/assets/images/icons/Crown.vue'

export default {
  components: {
    BCard, BButton, BModal, DatePicker, Crown
  },
  data() {
    return {
      pageArray: [],
      pageArrayStock: [],

      detailModal: false,
      detailObject: {},

      verifyModal: false,
      verifyObject: {},

      addModal: false,
      addObject: {
        tournamentName: '',
        tournamentPrice: '',
        tournamentCup1: '',
        tournamentCup2: '',
        tournamentCup3: '',
        tournamentCup4: '',
        tournamentJoinerStart: '',
      },

      editModal: false,
      editObject: {
        tRaceLimit: '',
        tRaceParentArea: '',
        tRaceStepArea: '',
        tRaceStepNumber: '',
        tournamentCup3: '',
        tournamentCup4: '',
        tournamentJoinerStart: '',
      },

      editTransferDate: '',
      datePicker: '',
      datePickerShow: '',

      filterTrackname: '',
      filterTrackbet: '',
      filterTracklap: '',
      filterTracklimit: '',
      filterTracklimit: '',
      filterTracklimit: '',
    }
  },
  mounted() {
    this.mountedData()
  },
  methods: {
    async mountedData() {
      this.detailModal = false,

        this.addObject = {
          tournamentName: '',
          tournamentPrice: '',
          tournamentCup1: '',
          tournamentCup2: '',
          tournamentCup3: '',
          tournamentCup4: '',
          tournamentJoinerStart: '',
        },
        await this.$http.get('tournament/admin/list')
          .then(response => {
            this.pageArrayStock = this.pageArray = response.data
          }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async tableInteractive(item, index, type) {
      if (type === 'detail') {
        this.editObject = structuredClone(item)
        this.datePickerShow = new Date(`${this.$moment.utc(item.tournamentJoinerStart).format('YYYY-MM-DD')}T23:59:00.000Z`)
        this.editModal = true
        this.getTournament(item.tournamentId)
      } else if (type === 'genarate') {
        this.generate(this.detailObject.tournamentId)
      } else if (type === 'verify') {
        this.verify(item.tournamentId)
      }
    },
    routeTournament(item) {
      this.$root.$router.push({
        path: `/tournament/${item.tournamentName}`,
        params: { errors: 'error' },
        query: { tournamentId: item.tournamentId }
      })
    },
    async generate(id) {
      await this.$http.post(`/tournament/generate`, {
        tournamentId: id
      })
        .then(res => {
          this.detailModal = false
          this.mountedData()
        }).catch(error => {
          let errorMessage = ''
          switch (error.response.data) {
            case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMessage,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    async verify(id) {
      await this.$http.post(`/tournament/verify`, {
        tournamentId: id
      })
        .then(res => {
          this.detailModal = false
          this.mountedData()
        }).catch(error => {
          let errorMessage = ''
          switch (error.response.data) {
            case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMessage,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    async getTournament(id) {
      await this.$http.get(`/tournament/admin/get/${id}`)
        .then(res => {
          this.detailModal = true
          this.detailObject = structuredClone(res.data)
        }).catch(error => {
          let errorMessage = ''
          switch (error.response.data) {
            case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMessage,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    async saveData(type) {
      if (type === 'create') {
        await this.$http.post('/tournament/create', this.addObject)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.addModal = false
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }

      if (type === 'update') {
        await this.$http({
          method: 'POST',
          url: '/features/tracks/update',
          data: this.detailObject,
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            // eslint-disable-next-line default-case
            switch (error.response.data) {
              case 'AdminEmailIsRegistered': errorMessage = 'Admin Email is Valid'; break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async handlePicker(value) {
      this.editObject.tournamentJoinerStart = await this.$moment(new Date(value)).format('YYYY-MM-DD')
      this.addObject.tournamentJoinerStart = await this.$moment(new Date(value)).format('YYYY-MM-DD')
      this.datePickerShow = new Date(`${this.$moment.utc(value).format('YYYY-MM-DD')}T23:59:00.000Z`)
    },
  },
}
</script>

<template>
  <section>
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Tourment List</a>
      <button class="tb-button bg-primary ml-auto" @click="addModal = true">
        <a class="text-white">Create Tourment</a>
      </button>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%">
              <input type="text" class="form-control" placeholder="#" disabled readonly>
            </th>
            <th style="width: 15%"><input v-model="filterTrackname" type="text" class="form-control"
                placeholder="Tournament Name"></th>
            <th style="width: 12%"><input v-model="filterTrackbet" type="text" class="form-control"
                placeholder="Tournament Price"></th>
            <th style="width: 12%"><input v-model="filterTracklap" type="text" class="form-control"
                placeholder="Tournament Cup1"></th>
            <th style="width: 12%"><input v-model="filterTracklimit" type="text" class="form-control"
                placeholder="Tournament Cup2"></th>
            <th style="width: 12%"><input v-model="filterTracklimit" type="text" class="form-control"
                placeholder="Tournament Cup3"></th>
            <th style="width: 12%"><input v-model="filterTracklimit" type="text" class="form-control"
                placeholder="Tournament Cup4"></th>
            <th style="width: 15%">
              <input type="text" class="form-control" placeholder="Options" disabled readonly>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.tournamentName }}</a></td>
            <td><a>{{ item.tournamentPrice }}</a></td>
            <td><a>{{ item.tournamentCup1 }}</a></td>
            <td><a>{{ item.tournamentCup2 }}</a></td>
            <td><a>{{ item.tournamentCup3 }}</a></td>
            <td><a>{{ item.tournamentCup4 }}</a></td>
            <td class="d-flex justify-content-around">
              <button class="tb-button bg-primary" @click="tableInteractive(item, index, 'detail')">
                <span class="text-white">Detail</span>
              </button>
              <button class="tb-button bg-info" @click="routeTournament(item)">
                <span class="text-white">Inside</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Tournament Detail -->
    <b-modal v-model="detailModal" size="xl" hide-header hide-footer centered>
      <div class="row">
        <div class="col-3 text-center">
          <a class="tb-page-title">Create track</a>
          <hr>
        </div>
        <div class="col-9 text-center">
          <a class="tb-page-title">User List</a>
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="col-12">
            <label>Tournament Name</label>
            <input v-model="detailObject.tournamentName" type="text" class="form-control" readonly disabled>
          </div>
          <div class="col-12 mt-1">
            <label>Tournament Price</label>
            <input v-model.number="detailObject.tournamentPrice" type="number" class="form-control" readonly disabled>
          </div>
          <div class="col-12 mt-1">
            <label>Tournament Cup 1</label>
            <input v-model="detailObject.tournamentCup1" type="text" class="form-control" readonly disabled>
          </div>
          <div class="col-12 mt-1">
            <label>Tournament Cup 2</label>
            <input v-model="detailObject.tournamentCup2" type="text" class="form-control" readonly disabled>
          </div>
          <div class="col-12 mt-1">
            <label>Tournament Cup 3</label>
            <input v-model="detailObject.tournamentCup3" type="text" class="form-control" readonly disabled>
          </div>
          <div class="col-12 mt-1">
            <label>Tournament Cup 4</label>
            <input v-model="detailObject.tournamentCup4" type="text" class="form-control" readonly disabled>
          </div>
          <div class="col-12 mt-1">
            <label>Tournament Joiner Start</label>
            <date-picker v-model="datePickerShow" @change="handlePicker" class="ua-h-40" style="width: 100%"
              format="DD-MM-YYYY" readonly disabled />
          </div>
        </div>
        <div class="col-9">
          <div class="row" style="position:relative; overflow-y:auto; height: 60vh">
            <div class="col-3 p-1" v-for="(item,index) in detailObject.users" :key="index">
              <div class="col-12 tb-border-color-light p-1 rounded-lg h-100">
                <table style="width:100%">
                  <tr>
                    <th>Name:</th>
                    <td>{{ item.userUsername }} {{ item.userLastname }}
                    </td>
                  </tr>
                  <tr>
                    <th>User Name:</th>
                    <td>{{ item.userUsername }}
                    </td>
                  </tr>
                  <tr>
                    <th>Language:</th>
                    <td>{{ item.userLanguage ? item.userLanguage.toUpperCase() : '' }}</td>
                  </tr>
                  <tr>
                    <th>Wallet ID:</th>
                    <td>{{ item.userWalletId.substring(1,10) }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-3 text-center">
          <hr>
          <b-button variant="outline-primary" class="my-1 d-none" @click="saveData('update')">Update</b-button>
        </div>
        <div class="col-9 text-center">
          <hr>
          <b-button v-if="detailObject.tournamentStatus == 1" class="col-6 my-1 bg-info"
            @click="tableInteractive(detailObject, 1, 'genarate')">Generate</b-button>
          <b-button v-else class="col-6 my-1 bg-success" @click="tableInteractive(detailObject, 2, 'verify')">
            Verify</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Add Modal -->
    <b-modal v-model="addModal" hide-header hide-footer centered>
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create track</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Tournament Name</label>
          <input v-model="addObject.tournamentName" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Tournament Price</label>
          <input v-model.number="addObject.tournamentPrice" type="number" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Tournament Cup 1</label>
          <input v-model="addObject.tournamentCup1" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Tournament Cup 2</label>
          <input v-model="addObject.tournamentCup2" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Tournament Cup 3</label>
          <input v-model="addObject.tournamentCup3" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Tournament Cup 4</label>
          <input v-model="addObject.tournamentCup4" type="text" class="form-control">
        </div>
        <div class="col-12 mt-1">
          <label>Tournament Joiner Start</label>
          <date-picker v-model="datePickerShow" @change="handlePicker" class="ua-h-40" style="width: 100%"
            format="DD-MM-YYYY" />
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" @click="saveData('create')">Create & Close</b-button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<style scoped>
th,
td {
  padding: 10px;
}

tr:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}

tr:nth-child(even) {
  background-color: rgb(228, 228, 228);
}
</style>